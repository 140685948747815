<mat-toolbar>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <button class="btn-custom" [routerLink]="['new']">New Configuration</button>
        <button class="btn-custom" (click)="importCarrier()">Import from csv</button>
        <input #carrierFile type="file" style="display: none" (change)="carrierFileChanged($event)" />
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="container-fluid margin-bottom-60">
  <div class="row padding-top-10">
    <div class="col-md-7">
      <div class="row">
        <div class="col-md-2">
          <mat-form-field class="col">
            <mat-select placeholder="Carrier" [(ngModel)]="filters.carrier">
              <mat-option *ngFor="let c of carriers" [value]="c.code">{{ c.carrierName }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <input matInput placeholder="Origin" [(ngModel)]="filters.originCountryCode" maxlength="2" />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <input matInput placeholder="Destination" [(ngModel)]="filters.destinationCountryCode" maxlength="2" />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <app-brandon-autocomplete
            style="display: block"
            cssClass="col"
            label="Supplier"
            [allData]="suppliers"
            [(value)]="filters.supplierCode"
            filterKey="description"
            displayKey="description"
            valueKey="code"
          ></app-brandon-autocomplete>
        </div>
        <div class="col-md-2">
          <mat-form-field class="col">
            <input matInput [(ngModel)]="filters.storeCode" placeholder="Store Code" />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field class="col">
            <input matInput [(ngModel)]="filters.storeGroup" placeholder="Store Group" />
          </mat-form-field>
        </div>
        <div class="col-md-4 pt-3">
          <button (click)="search()">
            <i class="fa fa-search me-1" aria-hidden="true"></i>
            Search
          </button>
          <button class="btn-icon ms-2" (click)="reset()"><i class="fa fa-undo" aria-hidden="true"></i></button>
        </div>
      </div>
    </div>

    <div class="col-md-5">
      <mat-paginator
        [length]="resultsNumber | async"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="loadPage()"
      ></mat-paginator>
    </div>
  </div>
  <!-- Da attivare quando ci saranno i filtri attivi -->
  <div class="table-container mb-5">
    <table mat-table [dataSource]="dataSource" class="table">
      <ng-container matColumnDef="carrier">
        <th mat-header-cell *matHeaderCellDef>Carrier</th>
        <td mat-cell *matCellDef="let c">{{ c.carrier.carrierName }}</td>
      </ng-container>

      <ng-container matColumnDef="originCountryCode">
        <th mat-header-cell *matHeaderCellDef>Origin</th>
        <td mat-cell *matCellDef="let c">{{ c.originCountryCode }}</td>
      </ng-container>

      <ng-container matColumnDef="destinationCountryCode">
        <th mat-header-cell *matHeaderCellDef>Destination</th>
        <td mat-cell *matCellDef="let c">{{ c.destinationCountryCode }}</td>
      </ng-container>

      <ng-container matColumnDef="destinationZipCodePrefix">
        <th mat-header-cell *matHeaderCellDef>Dest ZipCode</th>
        <td mat-cell *matCellDef="let c">{{ c.destinationZipCodePrefix }}</td>
      </ng-container>

      <ng-container matColumnDef="contract">
        <th mat-header-cell *matHeaderCellDef>Contract</th>
        <td mat-cell *matCellDef="let c">{{ c.contract }}</td>
      </ng-container>

      <ng-container matColumnDef="weightKgFrom">
        <th mat-header-cell *matHeaderCellDef>From Weight</th>
        <td mat-cell *matCellDef="let c">{{ c.weightKgFrom }} <span *ngIf="c.weightKgFrom"> kg </span></td>
      </ng-container>

      <ng-container matColumnDef="weightKgTo">
        <th mat-header-cell *matHeaderCellDef>To Weight</th>
        <td mat-cell *matCellDef="let c">{{ c.weightKgTo }} <span *ngIf="c.weightKgTo"> kg </span></td>
      </ng-container>

      <ng-container matColumnDef="storeCode">
        <th mat-header-cell *matHeaderCellDef>Store Code</th>
        <td mat-cell *matCellDef="let c">{{ c.storeCode }}</td>
      </ng-container>

      <ng-container matColumnDef="storeGroupCode">
        <th mat-header-cell *matHeaderCellDef>Store Group Code</th>
        <td mat-cell *matCellDef="let c">{{ c.storeGroupCode }}</td>
      </ng-container>

      <ng-container matColumnDef="supplierCode">
        <th mat-header-cell *matHeaderCellDef>Supplier Code</th>
        <td mat-cell *matCellDef="let c">{{ c.supplierCode }}</td>
      </ng-container>

      <ng-container matColumnDef="orderType">
        <th mat-header-cell *matHeaderCellDef>Order Type</th>
        <td mat-cell *matCellDef="let c">{{ c.orderType }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell class="button-column" *matHeaderCellDef></th>
        <td mat-cell class="button-column" *matCellDef="let c">
          <button class="btn-sm" [routerLink]="['edit', c.id]">
            <i class="fa fa-pencil-square-o me-1" aria-hidden="true"></i>
            Edit
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell class="button-column" *matHeaderCellDef></th>
        <td mat-cell class="button-column" *matCellDef="let c">
          <button class="btn btn-danger btn-icon rounded-circle" style="width: 38px" (click)="deleteConf(c.id)">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
          </button>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns" class="sticky-top"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
  </div>
</div>
